<template>
    <div class="card shadow">
        <div class="card-body authorization-content-message d-flex flex-column justify-content-between">
            <div>
                <p class="text-center mb-4">
                    <img src="@/assets/images/tendencys.svg" class=" img-80" alt="">
                </p>
                <h3 class="text-center animated fadeIn mb-2" v-text="$t('messages.accountAuthorization')"></h3>
                <p class="text-center f-w-500" v-text="$t('messages.tendnecysAccount')"></p>
            </div>
            <p class="my-3 text-center" v-html="$t('messages.alreadyAccount')"></p>
            <div class="row justify-content-center">
                <div class="col-auto">
                    <div class="rounded-pill border border-2 border-primary py-2 px-3">
                        <p class=" f-14 text-center mb-0">
                            <i class="fa fa-user-circle fa-lg mr-2"></i>
                            <strong v-text="$route.query.email"> </strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-6">
                    <b-button variant="light w-100 " @click="fnApiLogout()" v-text="$t('general.logout')">
                    </b-button>
                </div>
                <div class="col-6">
                    <b-button variant="success w-100 " @click="fnApiAuthorizationAccount()"
                        v-text="$t('general.continue')">
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                account: {
                    account: null,
                    siteId: null,
                    to: null,
                }
            }
        },
        methods: {
            fnApiLogout() {
                window.location.replace(`/accounts/login?site_id=${this.$route.query.site_id}&email=${this.$route.query.email}&to=${this.$route.query.to}&logout=true`);
            },
            fnApiAuthorizationAccount() {
                if (('site_id' in this.$route.query) && ('account' in this.$route.query) && ('to' in this.$route
                        .query)) {
                    delete this.$route.query.email;
                    this.$route.query.auth = true;
                    axiosAccount.post('login/existing', this.$route.query).then((response) => {
                        window.location.replace(response.data);
                    }).catch(() => {});
                } else {
                    alert('Miss information');
                }
            }
        },
    }
</script>

<style lang="scss">
    .authorization {
        background-color: gray;
        padding-top: 25vh;
        .authorization-content {
            min-height: 75vh;
            background-color: white;
            .authorization-content-card {
                transform: translateY(-10vh);
            }
            .authorization-content-message {
                min-height: 25vh;
            }
        }
    }
</style>